<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-row :gutter="24" class="form-row-margin">
      <a-col :span="12">
        <a-form-model-item :label="$t('safety.deviceName')" prop="deviceName">
          <a-input
            :placeholder="$t('safety.inputDeviceName')"
            v-model="form.deviceName"
            :maxLength="32"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item :label="$t('safety.deviceCode')" prop="deviceCode">
          <a-input
            :placeholder="$t('safety.inputDeviceCode')"
            v-model="form.deviceCode"
            :disabled="!canEdit"
            :maxLength="128"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="24" class="form-row-margin">
      <a-col :span="12">
        <a-form-model-item label="设备密钥" prop="deviceKey">
          <a-input
            placeholder="请输入设备密钥"
            v-model="form.deviceKey"
            :disabled="!canEdit"
            :maxLength="16"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item :label="$t('safety.belongProject')" prop="projectId">
          <a-select
            show-search
            allowClear
            v-model="form.projectId"
            :placeholder="$t('safety.chooseProject')"
            @change="handleProjectChange"
            option-filter-prop="children"
            :filter-option="true"
          >
            <a-select-option
              :value="item.pkId"
              v-for="item in projectList"
              :key="item.pkId"
            >
              {{ item.projectName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="24">
      <a-col :span="12">
        <a-form-model-item label="联动设备">
          <a-select
            allowClear
            v-model="form.relatedDeviceType"
            placeholder="请选择设备类型"
            style="margin-bottom: 10px;"
            @change="handleTypeChange"
          >
            <a-select-option value="TCSF">
              塔机
            </a-select-option>
            <a-select-option value="ED">
              升降机
            </a-select-option>
            <a-select-option value="UP">
              卸料平台
            </a-select-option>
          </a-select>
          <a-select
            show-search
            allowClear
            v-model="form.relatedDeviceId"
            placeholder="请选择设备"
            option-filter-prop="children"
            :filter-option="true"
          >
            <a-select-option
              :value="item.pkId"
              v-for="item in devices"
              :key="item.pkId"
            >
              {{ item.deviceName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item :label="$t('common.remark')" prop="remark">
          <a-input
            :placeholder="$t('safety.inputRemark')"
            v-model="form.remark"
            :maxLength="128"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { deviceTypeEnum } from '@/enum/device';
import FormCommon from '@/views/green-construction/components/mixins/form-common';
import { deviceList } from '@/services/device-manage/device';

@Component()
export default class ScrewForm extends mixins(FormCommon) {
  form = {
    deviceName: '',
    deviceCode: '',
    projectId: undefined,
    deviceKey: '',
    remark: '',
    relatedDeviceType: '',
    relatedDeviceId: '',
  };
  rules = {
    deviceName: [
      {
        required: true,
        message: this.$t('safety.requiredDeviceName'),
      },
    ],
    deviceCode: [
      {
        required: true,
        message: this.$t('safety.requiredDeviceCode'),
      },
    ],
    deviceKey: [
      {
        required: true,
        message: '设备密钥不能为空',
      },
    ],
  };
  get postData() {
    return {
      deviceGroup: deviceTypeEnum.SCREW.deviceGroup,
      deviceName: this.form.deviceName,
      deviceCode: this.form.deviceCode,
      idxProjectId: this.form.projectId,
      remark: this.form.remark,
      deviceKey: this.form.deviceKey,
      relatedDeviceType: this.form.relatedDeviceType,
      relatedDeviceId: [this.form.relatedDeviceId],
    };
  }
  async initFormData(data) {
    this.form.deviceName = data.deviceName;
    this.form.deviceCode = data.deviceCode;
    this.form.projectId = data.idxProjectId;
    this.form.remark = data.remark;
    this.form.deviceKey = data.encryptionKey;
    if (data.relatedDevice.length) {
      await this.getDeviceList(data.relatedDevice[0].deviceGroup);
      this.form.relatedDeviceType = data.relatedDevice[0].deviceGroup;
      this.form.relatedDeviceId = data.relatedDevice[0].pkId;
    }
  }
  devices = [];
  async getDeviceList(type) {
    const params = {
      projectId: this.form.projectId,
      query: {
        current: 1,
        size: 500,
      },
    };
    const { records } = await deviceList(
      params,
      deviceTypeEnum[type].deviceGroup,
    );
    this.devices = records;
  }
  handleTypeChange(type) {
    this.form.relatedDeviceId = undefined;
    this.getDeviceList(type);
  }
  handleProjectChange() {
    this.form.relatedDeviceId = undefined;
    if (this.form.relatedDeviceType) {
      this.getDeviceList(this.form.relatedDeviceType);
    }
  }
  valid = false;
  verify() {
    if (this.form.relatedDeviceType && !this.form.relatedDeviceId) {
      this.valid = false;
      this.$message.error('请选择设备');
    } else {
      this.valid = true;
    }
  }
}
</script>

<style></style>
