<template>
  <div :class="$style.content">
    <div>
      <table-search
        @handle-search-submit="handleSearchSubmit($event)"
        @handle-add-device="showFormModel({}, 'add')"
        :showStatusNum="false"
      />
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="pagination"
        :row-key="record => record.deviceId"
        :loading="loading"
        :transformCellText="({ text }) => text || '-'"
        @change="handlePagination"
      >
        <template slot="action" slot-scope="record">
          <button-groups
            @handle-edit="showFormModel(record, 'edit')"
            @handle-detail="showDetail(record)"
            @handle-delete="deleteTips(record)"
          />
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import FormModel from './screw-form.vue';
import ButtonGroups from '@/views/green-construction/components/button-groups.vue';
import tableSearch from '@/views/green-construction/components/table-search.vue';
import { deviceTypeEnum } from '@/enum/device';
import TableCommon from '@/views/green-construction/components/mixins/table-common';
import { createDrawer } from '@triascloud/x-components';
import screwDetail from './screw-detail';
import { pageTypeEnum } from '@/views/safety-device/component/safety-utils';

@Component({
  components: {
    FormModel,
    ButtonGroups,
    tableSearch,
  },
})
export default class ScrewMonitor extends mixins(TableCommon) {
  // 螺丝松动监测
  deviceGroup = deviceTypeEnum.SCREW.deviceGroup;
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, record, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('safety.index'),
        fixed: 'left',
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('safety.deviceName'),
        dataIndex: 'deviceName',
        ellipsis: true,
        width: 240,
      },
      {
        align: 'left',
        title: this.$t('safety.deviceCode'),
        dataIndex: 'deviceCode',
        ellipsis: true,
        width: 180,
      },
      {
        title: this.$t('safety.belongProject'),
        dataIndex: 'projectName',
        ellipsis: true,
        width: 240,
      },
      {
        align: 'left',
        title: this.$t('safety.lastDateTime'),
        dataIndex: 'lastDataTime',
        customRender: txt =>
          txt ? this.dayjs(txt).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        title: this.$t('common.remark'),
        dataIndex: 'remark',
      },
      {
        title: this.$t('common.operation'),
        fixed: 'right',
        key: 'operation',
        scopedSlots: { customRender: 'action' },
      },
    ];
  }

  pageType = pageTypeEnum.screwMonitor;
  showDetail(record) {
    this.modalDrawer = createDrawer(
      () => (
        <screwDetail
          detailData={record}
          pageType={this.pageType}
          close={this.handleCloseModalDrawer}
        />
      ),
      {
        title: this.$t('safety.deviceDetail'),
        width: '720px',
        wrapClassName: 'deviceDetail',
      },
    );
  }
}
</script>

<style lang="less" module>
@import '../component/safety.less';
.content {
  padding: 20px;
}
</style>
