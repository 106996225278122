<template>
  <div style="min-height: 400px">
    <ScreenSelect
      @changeTimeRange="selectTime"
      :typeOptions="typeOptions"
      :placeholder="$t('safety.monitorPoints')"
      @changeType="changeType"
    />
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="loading"
      @change="handlePagination"
      :transformCellText="({ text }) => (text === -1 ? '-' : text ?? '-')"
    />
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { deviceTypeEnum } from '@/enum/device';
import {
  crewLoosesStatusData,
  getCrewLooses,
} from '@/services/device-manage/device-detail';
import ScreenSelect from '@/views/safety-device/component/screen-select';

@Component({
  components: {
    ScreenSelect,
  },
})
export default class DeviceStatus extends Vue {
  @Prop({ type: String, default: '' }) pageType;
  @Prop({ type: String, default: '' }) deviceId;

  @Watch('deviceId')
  changeDeviceId(newVal, oldVal) {
    if (newVal && newVal !== oldVal) {
      this.deviceId = newVal;
      this.getTableList();
      this.getTypeList();
    }
  }
  mounted() {
    this.getTableList();
    this.getTypeList();
  }
  formateTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '';
  }
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, record, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('safety.index'),
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('safety.reportTime'),
        dataIndex: 'localeTime',
        customRender: txt => this.formateTime(txt),
      },
      {
        align: 'left',
        title: this.$t('safety.monitorPoint'),
        dataIndex: 'screwId',
      },
      {
        align: 'left',
        title: '螺母状态',
        dataIndex: 'status',
        customRender: txt => {
          if (txt !== -1) {
            return ['-', '正常', '松动报警', '断裂报警', '滑丝报警', '离线'][
              txt
            ];
          } else {
            return '-';
          }
        },
      },
    ];
  }
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };
  get deviceGroup() {
    return deviceTypeEnum[this.pageType].deviceGroup;
  }
  rangeTime = {
    startTime: '',
    endTime: '',
  };
  selectTime(t) {
    if (t && t.startTime) {
      this.rangeTime.startTime = t.startTime;
      this.rangeTime.endTime = t.endTime;
    } else {
      this.rangeTime = {
        startTime: '',
        endTime: '',
      };
    }
    this.pagination.current = 1;
    this.getTableList();
  }
  loading = false;
  typeOptions = [];
  async getTypeList() {
    if (!this.deviceId) {
      return;
    }
    const params = {
      deviceId: this.deviceId,
    };
    try {
      const res = await getCrewLooses(params, this.deviceGroup);
      this.typeOptions = res.map(v => ({
        label: v.text,
        value: v.value,
      }));
    } catch {
      return false;
    }
  }
  selectType = [];
  changeType(v) {
    if (v && v !== 'all') {
      this.selectType = v;
    } else {
      this.selectType = '';
    }
    this.pagination.current = 1;
    this.getTableList();
  }
  tableData = [];
  async getTableList() {
    if (!this.deviceId) {
      return;
    }
    this.tableData = [];
    const params = {
      pkId: this.deviceId,
      current: this.pagination.current,
      size: this.pagination.pageSize,
    };
    if (this.rangeTime.startTime) {
      params.startTime = this.rangeTime.startTime;
      params.endTime = this.rangeTime.endTime;
    }
    if (this.selectType.value) {
      params.luomusn = this.selectType.value;
    }
    try {
      this.loading = true;
      const { records, total } = await crewLoosesStatusData(
        params,
        this.deviceGroup,
      );
      this.tableData = records;
      this.pagination.total = total;
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  handlePagination(data) {
    this.pagination = data;
    this.getTableList();
  }
}
</script>
